import { G2Review } from '@/store/modules/G2Review'
import { account } from '@/store/modules/account'
import { auth } from '@/store/modules/authentication'
import { automationFlow } from '@/store/modules/automationFlow'
import { automationFlows } from '@/store/modules/automationFlows'
import { chat } from '@/store/modules/chat'
import { chatNotification } from '@/store/modules/chatNotification'
import { contentGeneration } from '@/store/modules/contentGeneration'
import { contentPillars } from '@/store/modules/contentPillars'
import { conversation } from '@/store/modules/conversation'
import { file } from '@/store/modules/file'
import { folder } from '@/store/modules/folder'
import { invitations } from '@/store/modules/invitations'
import { libraries } from '@/store/modules/libraries'
import { library } from '@/store/modules/library'
import { loading } from '@/store/modules/loading'
import { modals } from '@/store/modules/modals'
import { navigation } from '@/store/modules/navigation'
import { newUserTerms } from '@/store/modules/newUserTerms'
import { passwordReset } from '@/store/modules/passwordReset'
import { payment } from '@/store/modules/payment'
import { phraseConnector } from '@/store/modules/phraseConnector'
import { pricing } from '@/store/modules/pricing'
import { project } from '@/store/modules/project'
import { projects } from '@/store/modules/projects'
import { registration } from '@/store/modules/registration'
import { sso } from '@/store/modules/sso'
import { subscriptions } from '@/store/modules/subscriptions'
import { copyediting } from '@/store/modules/supplier/copyediting'
import { supplierDashboard } from '@/store/modules/supplier/dashboard'
import { file as supplierFile } from '@/store/modules/supplier/file'
import { invoice } from '@/store/modules/supplier/invoice'
import { services } from '@/store/modules/supplier/services'
import { supplier } from '@/store/modules/supplier/supplier'
import { supplierOnboarding } from '@/store/modules/supplier/supplierOnboarding'
import { translationFile as supplierTranslationFile } from '@/store/modules/supplier/translationFile'
import { supplierChat } from '@/store/modules/supplierChat'
import { task } from '@/store/modules/task'
import { tasks } from '@/store/modules/tasks'
import { teams } from '@/store/modules/teams'
import { termBanks } from '@/store/modules/termBanks'
import { toast } from '@/store/modules/toast'
import { translationMemories } from '@/store/modules/translationMemories'
import { translators } from '@/store/modules/translators'
import { wallet } from '@/store/modules/wallet'
import { workspace } from '@/store/modules/workspace'
import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'

const debug = process.env.NODE_ENV !== 'production'

export default createStore(Store, {
  modules: {
    automationFlow,
    automationFlows,
    wallet,
    auth,
    supplierOnboarding,
    services,
    workspace,
    navigation,
    translationMemories,
    termBanks,
    tasks,
    modals,
    contentPillars,
    account,
    payment,
    pricing,
    newUserTerms,
    invitations,
    passwordReset,
    projects,
    supplier,
    task,
    conversation,
    registration,
    invoice,
    project,
    toast,
    folder,
    supplierDashboard,
    libraries,
    library,
    loading,
    chat,
    translators,
    supplierChat,
    chatNotification,
    teams,
    contentGeneration,
    copyediting,
    subscriptions,
    sso,
    G2Review,
    file,
    supplierFile,
    supplierTranslationFile,
    phraseConnector
  },
  strict: debug
})
